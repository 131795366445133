import React from 'react'
//Router dom
import { Link } from 'react-router-dom'
//Assets
import LinkArrow from '../../../assets/misc/link_arrow_white.svg'

export const Direct = ({ data, setShowMobile, setShowSearch }) => {
  return(
    <Link 
      onClick={(e) => {
        e.persist()
        setShowSearch(false)
        return setShowMobile(false)
      }}
      to={`/${data.linksTo}`} 
      className="navbar-container-items-item">
      <div className="navbar-container-items-item-title">
        <p>{data.title}</p>
      </div>
      <div className="navbar-container-items-item-direct-link">
        <img
          src={LinkArrow}
          alt={`Flecha de link del item ${data.idx+1}`}
        />
      </div>
    </Link>
  )
}

export default Direct