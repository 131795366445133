import React from 'react'

//Assets
import Logo from '../../assets/brand/luckytrade_logo_color.svg'

const Loading = ({ page }) => {

  return(
    <div
      className="loading"
    >
      <div
        className="loading-logo"
      >
        <img 
          alt="Lucky Trade"
          src={Logo}
        />
      </div>
      <div
        className="loading-spinner"
      >
        Loading...
      </div>
      <div
        className="loading-text"
      >
        Cargando {page ? `${page}...` : "..."}
      </div>
    </div>
  )
}

export default Loading