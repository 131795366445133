import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const Processes = ({data}) => {

  //Page
  const [page, setPage] = useState(0)
  //Windows width
  const winWidth = useSelector(state => state.winDimensions.winWidth)

  //Pagination helper
  const pagination = () => {
    //Aux variables
    let i = 0
    const helperArray = []
    const pages = winWidth > 768 ? 
      Math.ceil( data.items.length / data.pageSize ) : //Desktop
      Math.ceil( data.items.length / data.pageSizeMobile ) //Mobile

    //Do nothing for one page
    if(pages === 1) return

    //Build helper array
    while(i < pages){
      helperArray.push(null)
      i += 1
    }

    //Return what is needed
    return helperArray.map((_, idx) => 
      <div 
        key={`home-processes-page[${idx+1}]`}
        className={`home-processes-carousel-pagination-page ${page === idx ? "selected" : ""}`}
        onClick={(e) => {
          e.persist()
          return setPage(idx)
        }}
        >
        <p>
          { idx+1 < 10 ? `0${idx+1}` : idx+1 }
        </p>
      </div>
    )

  }

  //Set visibility of a client
  const setVisibility = (idx) => {
    let clientPage = 0
    if(data){
      if(winWidth > 768){ //Desktop
        clientPage = Math.floor( idx / data.pageSize )
      }
      else{ //Mobile
        clientPage = Math.floor( idx / data.pageSizeMobile )
      }
    }
    return clientPage === page
  }

  return (
    <section
      className="home-processes"
      style={{
        backgroundImage: `url(${data ? (winWidth <= 768 ? data.gridImageMobile : data.gridImage ) : ""}), url(${data ? data.background : ""})`,
        backgroundColor: 'rgb(7, 98, 129)'
      }}
    >
      <div
        className="home-processes-grid"
      >
      </div>
      <div
        className="home-processes-title"
        dangerouslySetInnerHTML={{
          __html: data ? data.title : "<h2>Cargando...</h2>"
        }}
      >
      </div>
      <div
        className="home-processes-carousel"
      >
        <div
          className="home-processes-carousel-items"
        >
          {
            data && data.items.map((item, idx) =>
              <div
                key={`home-processes-item-${idx+1}`}
                className={`home-processes-carousel-items-item ${setVisibility(idx) ? "visible" : ""}`}
              >
                <div
                  className="home-processes-carousel-items-item-image"
                >
                  <img 
                    alt={`Proceso #${idx+1}`}
                    src={item.imageUrl}
                  />
                </div>
                <div
                  className="home-processes-carousel-items-item-title"
                >
                  <p>
                    {item.title}
                  </p>
                </div>
              </div>
            )
          }
        </div>
        <div
          className="home-processes-carousel-pagination"
        >
          {
            data && pagination()
          }
        </div>
      </div>
    </section>
  )
}

export default Processes