import firebase from "../firebase/firebaseConfig";
import rootReducer from "./rootReducer";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";
import {
  reduxFirestore,
  getFirestore,
  createFirestoreInstance
} from "redux-firestore";

const configureAppStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    enhancers: [reduxFirestore(firebase)],
    middleware: getDefaultMiddleware({
      thunk: {
        extraArgument: { getFirebase, getFirestore }
      },
      serializableCheck: false
    })
  });

  const rrfConfig = {
    // userProfile: "users", // <- For profile storage in firestore
    useFirestoreForProfile: false,
    attachAuthIsReady: false,
    logErrors: true
  };

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
  };

  return [store, rrfProps];
};

const [store, rrfProps] = configureAppStore();

export { store, rrfProps };