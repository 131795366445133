import React, { useState } from 'react'

import Search from '../../assets/misc/search_icon.svg'
import SearchDark from '../../assets/misc/search_icon_dark.svg'

//Child components
import Direct from '../Navbar/Items/Direct'
import Dropdown from '../Navbar/Items/Dropdown'
import DirectFooter from '../Footer/Items/Direct'
import DropdownFooter from '../Footer/Items/Dropdown'

const SearchBar = ({ prefix, items, setShowMobile, setShowSearch }) => {

  const [query, setQuery] = useState('')

  const handleChange = (e) => {
    e.persist()
    setQuery(e.target.value)
  }

  //Navbar search box items
  const navbarItems = (item, idx) =>
    item.type === "direct" ? 
      <Direct 
        key={`navbar-search-item-${idx+1}`}
        data={{
          ...item,
          idx
        }}
        setShowMobile={setShowMobile}
        setShowSearch={setShowSearch}
      /> : 
      <Dropdown 
        key={`navbar-item-${idx+1}`}
        data={{
          ...item,
          idx
        }}
        setShowMobile={setShowMobile}
        setShowSearch={setShowSearch}
      />

  const footerItems = (item, idx) => 
    item.type === "direct" ? 
      <DirectFooter 
        key={`footer-search-item-${idx+1}`}
        data={{
          ...item,
          idx
        }}
        setShowMobile={setShowMobile}
        setShowSearch={setShowSearch}
      /> : 
      <DropdownFooter
        key={`footer-search-item-${idx+1}`}
        data={{
          ...item,
          idx
        }}
        setShowMobile={setShowMobile}
        setShowSearch={setShowSearch}
      />
      

  return (
    <div
      className={`${prefix}-search`}
    >
      <div className={`${prefix}-search-input`}>
        <input
          id="query"
          name="query"
          type="text"
          value={query}
          onChange={handleChange}
          autoComplete="off"
        />
        <img 
          alt={`Buscar desde `}
          src={prefix === 'footer' ? SearchDark : Search}
        />
      </div>
      {
        items && query.length > 0 && items
          //Filter disables
          .filter(item => !item.disabled)
          //Filter query
          .filter(item => item.title.toUpperCase().indexOf(query.toUpperCase()) > -1)
          //Map
          .map((item, idx) => 
            <div
              key={`${prefix}-search-result-${idx+1}`}
              className={`${prefix}-search-input-result`}
              style={ prefix === 'footer' ? {
                position: 'absolute',
                bottom: `${60*(idx+1)}px`
              } : {
                position: 'absolute',
                top: `${60*(idx+1)}px`
              }}
            >
              {
                prefix === 'footer' ?
                footerItems(item, idx) : navbarItems(item, idx)
              }
            </div>)
      }
    </div>
  )
}

export default SearchBar