import React from 'react'
import { useSelector } from 'react-redux'

const Section2 = ({ data }) => {

  //windows size
  const winWidth = useSelector(state => state.winDimensions.winWidth)

  return (
    <section
      className="home-section2"
      style={
        data ?
        {
          backgroundImage: `url(${data ? (winWidth <= 768 ? data.gridImageMobile : data.gridImage ) : ""}), url(${data ? data.background : ""})`,
          backgroundColor: 'white'
        } : {}
      }
    >
      <div className="home-section2-items">
        <div className="home-section2-items-left">
          <div
            className="home-section2-description"
            dangerouslySetInnerHTML={{
              __html: data ? data.description : "<h2>Cargando...</h2>"
            }}
          ></div>
          {
            data && data.items.map((item, idx) => idx < 2 &&
              <div key={`home-section2-items-left-item-${idx+1}`} className="home-section2-items-left-item">
                <div className="home-section2-items-left-item-number">
                  <p>{idx+1<10 ? `0${idx+1}` : idx+1}</p>
                </div>
                <div className="home-section2-items-left-item-texts">
                  <div className="home-section2-items-left-item-texts-title">
                    <p>
                      {item.title}
                    </p>
                  </div>
                  <div className="home-section2-items-left-item-texts-description">
                    <p>
                      {
                        item.description
                      }
                    </p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className="home-section2-items-center">
          <img 
            alt="Sección 2 centro"
            src={data ? data.centerImage : ""}
          />
        </div>
        <div className="home-section2-items-right">
          {
            data && data.items.map((item, idx) => idx > 1 &&
              <div key={`home-section2-items-right-item-${idx+1}`} className="home-section2-items-right-item">
                <div className="home-section2-items-right-item-number">
                  <p>{idx+1<10 ? `0${idx+1}` : idx+1}</p>
                </div>
                <div className="home-section2-items-right-item-texts">
                  <div className="home-section2-items-right-item-texts-title">
                    <p>
                      {item.title}
                    </p>
                  </div>
                  <div className="home-section2-items-right-item-texts-description">
                    <p>
                      {
                        item.description
                      }
                    </p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default Section2