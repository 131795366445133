import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

var config={
  apiKey: "AIzaSyCMZDAcN51w89fvG5eSKnRKWodA9ox6J-k",
  authDomain: "luckytrade2021.firebaseapp.com",
  databaseURL: "https://luckytrade2021.firebaseio.com",
  projectId: "luckytrade2021",
  storageBucket: "luckytrade2021.appspot.com",
  messagingSenderId: "909595495573",
  appId: "1:909595495573:web:8b0b465cc42a5005c91f9c",
  measurementId: "G-Z374W2WLB5"
}

firebase.initializeApp(config);

firebase.firestore()

firebase.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code === 'failed-precondition') {
        console.log("There are multiple tabs opened")
      } else if (err.code === 'unimplemented') {
        console.log("Current browser doest support persistance")
      }
  })

export default firebase;