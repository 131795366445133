import React, { Suspense } from 'react'
//Child components
import Navbar from './Navbar/Navbar'
import Home from './Home/Home'
import Loading from './Loading/Loading'
//Router imports
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { routerConfig } from '../config/router/routes'


const Main = () => {

  const loading = () => <Loading />

  return (
    <>
      <BrowserRouter>
        <Navbar
        />
        <Suspense fallback={loading()}>
          <Switch>  
              {routerConfig.routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} />
                    )} />
                ) : (null);
            })}
            <Route exact path="*" component={Home}/>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  )
}

export default Main