import React from 'react'
import { useSelector } from 'react-redux'

import Industries from './Industries'

const WhyLuckyTrade = ({data, homeData}) =>{

  //Window width
  const winWidth = useSelector(state => state.winDimensions.winWidth)

  return(
    <section className="home-whyLucky"
      style={ data ? {
        backgroundImage: `url(${data ? (winWidth <= 768 ? data.gridImageMobile : data.gridImage ) : ""}), url(${data ? data.background : ""})`,
        backgroundColor: 'rgb(7, 98, 129)'
      } : {}}
    >
      <div
        className="home-whyLucky-title"
        dangerouslySetInnerHTML={{
          __html: data ? data.title : "<h2>Cargando...</h2>"
        }}
      ></div>
      <div
        className="home-whyLucky-items"
      >
        {
          data && data.items.map((item, idx) => 
            <div key={`home-why-lucky-list-item-${idx+1}`} className="home-whyLucky-items-item">
              <div className="home-whyLucky-items-item-image">
                <img 
                  alt={`${item.title}`}
                  src={
                    winWidth > 768 ? item.imageUrl : item.imageUrlMobile
                  }
                />
              </div>
              <div className="home-whyLucky-items-item-content">
                <div className="home-whyLucky-items-item-content-number">
                  { idx+1 < 10 ? `0${idx+1}` : idx+1 }
                </div>
                <div className="home-whyLucky-items-item-content-title">
                  <p>
                    {item.title}
                  </p>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <Industries 
        data={
          homeData ? {
            background: homeData.whyLuckyBackgroundImageUrl,
            gridImage: homeData.whyLuckyGridImageUrl,
            gridImageMobile: homeData.whyLuckyGridImageUrlMobile,
            menuBackground: homeData.industriesMenuBackgroundImageUrl,
            title: homeData.industriesTitle
          } : null
        }
      />
    </section>
  )
}

export default WhyLuckyTrade