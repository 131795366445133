import React, { useState, useEffect } from 'react'
//Redux
import { useFirestore } from 'react-redux-firebase'
//Router dom
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
//Assets
import LinkArrow from '../../../assets/misc/link_arrow_white.svg'
import DropdownIcon from '../../../assets/misc/dropdown_white.svg'

const Dropdown = ({data, setShowMobile, setShowSearch}) => {

  const firestore = useFirestore()
  //Env
  const env = useSelector(state => state.env.mode || "development")
  //Is dropdown open
  const [isOpen, setIsOpen] = useState(false)
  
  //Get childs
  const childs = useSelector(state => 
    state.firestore.ordered[`${data.linksTo}`] ?
    state.firestore.ordered[`${data.linksTo}`]
    .filter((item) => !item.disabled)
    .map((item) => ({
      id: item.id,
      name: item.name
    }))
    : []
  )

  //Extract data from firestore
  useEffect(() => {
    //Logic to detect environment
    let firebaseEnv = ""
    if(env === "development" || env === "test")
      firebaseEnv = "Development"
    else if(env === "production")
      firebaseEnv = "Production"
    
    //Query
    firestore.get({
      collection: "Environments",
      doc: firebaseEnv,
      subcollections: [{
        collection: data.linksTo,
        orderBy: [
          ['position', 'asc']
        ]
      }],
      storeAs: `${data.linksTo}`
    })
    
  }, [firestore, env, data.linksTo])

  return(
    <div className="navbar-container-items-item">
      <div className="navbar-container-items-item-dropdown"
        onClick={(e) => {
          e.persist()
          return setIsOpen(!isOpen)
        }}
      >
        <div className={`navbar-container-items-item-dropdown-title ${isOpen ? "open" : ""}`}>
          <p>{data.title}</p>
          <img
            src={DropdownIcon}
            alt={`Dropdown del item ${data.idx+1}`}
          />
        </div>
        <div className={`navbar-container-items-item-dropdown-items ${isOpen ? "open" : ""}`}>
          {
            isOpen && childs.map((child, idx) => 
              <Link
                onClick={(e) => {
                  e.persist()
                  setShowSearch(false)
                  return setShowMobile(false)
                }}
                className="navbar-container-items-item-dropdown-items-item"
                key={`Dropdown-${data.idx+1}-child-${idx+1}`} to={`/${data.linksTo}/${child.id}`}>
                <div className="navbar-container-items-item-dropdown-items-item-title">
                  <p>
                    {child.name}
                  </p>
                </div>
                <div className="navbar-container-items-item-dropdown-items-item-button">
                  <img
                    src={LinkArrow}
                    alt={`Link del hijo ${idx+1} item ${data.idx+1}`}
                  />
                </div>
              </Link>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Dropdown