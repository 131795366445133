import React, { useState, useEffect } from 'react'

//Redux
import { useSelector } from 'react-redux'

//React router
import { Link } from 'react-router-dom'

//Assets
import LinkArrow from '../../../assets/misc/link_arrow_white.svg'

const Header = ({ data }) => {

  //Selected item
  const [selected, setSelected] = useState(0)
  //windows size
  const winWidth = useSelector(state => state.winDimensions.winWidth)

  //Change interval of images
  useEffect(() => {
    const interval = setInterval(() => {
      //Check data existence
      if(data){
        setSelected(selected => 
          selected === data.items.length - 1 ? 0 : selected + 1
        );
      }
    }, 15000)
    return () => {
      clearInterval(interval);
    };
  }, [data, selected])

  /**
   * Handles click in the next button
   */
  const handleNextClick = e => {
    e.persist()
    //Check data existance
    if(data){
      setSelected(
        selected + 1 === data.items.length ? 0 : selected + 1
      )
    }
  }

  return (
    <section
      className="home-header"
      style={{
        backgroundColor: 'rgb(7, 98, 129)'
      }}
    >
      <div className="home-header-grids"
        style={{
          backgroundImage: `url(${data ? (winWidth <= 768 ? data.gridImageMobile : data.gridImage ) : ""})`
        }}
      ></div>
      <div className={`home-header-top`}
        style={{
          backgroundImage: `url(${data ? data.items[selected].imageUrl : ""})`,
        }}>
        {
          data && data.items.map((item, idx) => 
            //Items
            <div
              key={`home-header-item-${idx+1}`} className={`home-header-item ${selected === idx ? "selected" : ""}`}
            >
              <div className="home-header-item-container">
                <h2 className="home-header-item-container-title">
                  {item.title}
                </h2>
                <p className="home-header-item-container-description">
                  {item.description}
                </p>
              </div>
            </div>
          )
        }
        <div className={`home-header-paginator`}>
          { //Pagination
            winWidth > 768 && data && data.items.map((_, idx) =>
              <div key={`home-header-item-${idx+1}`} className={`home-header-paginator-page ${selected === idx ? "selected" : ""}`}
                onClick={(e) => {
                  e.persist()
                  return setSelected(idx)
                }}
              >
                <p>{ idx+1 < 10 ? `0${idx+1}` : `${idx+1}` }</p>
              </div>
            )
          }
        </div>
        <Link 
          onClick={(e) => {
            e.persist()
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            })
          }}
          className={`home-header-more`} to="/Us">
          <hr className="home-header-more-separator" />
          <div className={`home-header-more-container`}>
            <p className="home-header-more-container-text">
              Descubre más
            </p>
            <img 
              className="home-header-more-container-direct"
              alt="Link a Nosotros"
              src={LinkArrow}
            />
          </div>
        </Link>
      </div>
      <div className={`home-header-bottom`}
        style={{
          backgroundImage: data ? `url(${selected+1 === data.items.length ?
            data.items[0].imageUrl : data.items[selected+1].imageUrl
          })`: ""
        }}
      >
        <div className="home-header-next"
          onClick={handleNextClick}
        >
          <p className="home-header-next-text">
            Siguiente
          </p>
        </div>
      </div>
    </section>
  )
}
export default Header