import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'

const Clients = ({ data }) => {

  //Page
  const [page, setPage] = useState(0)
  //Last client alone
  const [lastAlone, setLastAlone] = useState(false)
  //windows size
  const winWidth = useSelector(state => state.winDimensions.winWidth)
  //Firestore
  const firestore = useFirestore()
  //Env
  const env = useSelector(state => state.env.mode || "development")

  //Map state
  const clientsList = useSelector(state => 
    state.firestore.ordered.ClientsList ?
    state.firestore.ordered.ClientsList
      //Filter disabled
      .filter(client => !client.disabled)
      //Map for view
      .map(client => ({
        name: client.name,
        imageUrl: client.imageUrl
      })) :
    []
  )

  //Check if last item is alone
  useEffect(() => {
    //Check if list isnt empty and data exists
    if(clientsList.length > 0 && data){
      setLastAlone( _ => 
        winWidth <= 768 ? (
          false //Omit on mobile
        )
        : ( //Validate only for desktop
          (clientsList.length - 1) % data.pageSize === 0
        )
      )
    }
  }, [clientsList, data, winWidth])

    //Change interval of images
  useEffect(() => {
    const interval = setInterval(() => {
      //Check clientsList and data existence
      if(clientsList && data){
        //Desktop
        if(winWidth > 768){
          if(lastAlone){
            //Omit last page
            setPage(page => 
              page + 1 === Math.floor( clientsList.length / data.pageSize ) ? 0 : page + 1
            )
          }else{
            setPage(page => 
              page + 1 === Math.floor( clientsList.length / data.pageSize ) + 1 ? 0 : page + 1
            )
          }
        }else{
          //Mobile
          setPage(page => 
            page + 1 === Math.floor( clientsList.length / data.pageSizeMobile ) + 1 ? 0 : page + 1
          )
        }
      }
    }, 5000)

    return () => {
      clearInterval(interval);
    };
  }, [data, clientsList, lastAlone, winWidth])

  //Query Clients
  useEffect(() => {
    //Logic to detect environment
    let firebaseEnv = ""
    if(env === "development" || env === "test")
      firebaseEnv = "Development"
    else if(env === "production")
      firebaseEnv = "Production"
    
    //Query
    firestore.get({
      collection: "Environments",
      doc: firebaseEnv,
      subcollections: [{
        collection: 'Clients',
        orderBy: [
          ['position', 'asc']
        ]
      }],
      storeAs: 'ClientsList'
    })
    
  }, [firestore, env])

  //Pagination helper
  const pagination = () => {
    //Aux variables
    let i = 0
    const helperArray = []
    const pages = winWidth > 768 ? 
      Math.ceil( clientsList.length / data.pageSize ) : //Desktop
      Math.ceil( clientsList.length / data.pageSizeMobile ) //Mobile

    //Build helper array
    while(i < pages){
      helperArray.push(null)
      i += 1
    }

    //If last is alone pop only on desktop
    if(lastAlone && winWidth > 768){
      helperArray.pop()
    }

    //Return what is needed
    return helperArray.map((_, idx) => 
      <div 
        key={`home-clients-page[${idx+1}]`}
        className={`home-clients-carousel-pagination-page ${page === idx ? "selected" : ""}`}
        onClick={(e) => {
          e.persist()
          return setPage(idx)
        }}
        >
        <p>
          { idx+1 < 10 ? `0${idx+1}` : idx+1 }
        </p>
      </div>
    )

  }

  //Set visibility of a client
  const setVisibility = (idx) => {
    let clientPage = 0
    if(data){
      if(winWidth > 768){ //Desktop
        clientPage = Math.floor( idx / data.pageSize )
      }
      else{ //Mobile
        clientPage = Math.floor( idx / data.pageSizeMobile )
      }
      //If this is the last client and the last client is alone
      if(idx === clientsList.length - 1 && lastAlone){
        return clientPage === page + 1
      }
    }
    return clientPage === page
  }

  return (
    <section className="home-clients"
      style={{
        backgroundImage: `url(${data ? (winWidth <= 768 ? data.gridImageMobile : data.gridImage ) : ""}), url(${data ? data.background : ""})`,
      }}
    >
      <div className="home-clients-title"
        dangerouslySetInnerHTML={{
          __html: data ? data.title : "<h2>Cargando...</h2>"
        }}
      >
      </div>
      <div
        className="home-clients-carousel"
      >
        <div
          className="home-clients-carousel-items"
        >
          {
            //Clients
            clientsList.map((client, idx) => 
              <div
                key={`home-clients-item-${idx+1}`}
                className={
                  `home-clients-carousel-items-item ${ setVisibility(idx) ? "visible" : ""}`
                }
              >
                <img
                  alt={client.name}
                  src={client.imageUrl}
                />
              </div>
            )
          }
        </div>
        <div
          className="home-clients-carousel-pagination"
        >
          {
            //Pagination
            data && pagination()
          }
        </div>
      </div>
    </section>
  )
}

export default Clients