import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import envReducer from './envReducer'
import winDimensionReducer from './winDimensionsReducer'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  env: envReducer,
  winDimensions: winDimensionReducer
});

export default rootReducer;
