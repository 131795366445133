import React, { useEffect } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

//Child components
import Footer from '../Footer/Footer'
import Header from './Sections/Header'
import Section2 from './Sections/Section2'
import WhyLuckyTrade from './Sections/WhyLuckyTrade'
import Clients from './Sections/Clients'
import Processes from './Sections/Processes'
import Loading from '../Loading/Loading'

//React helmet
import { Helmet } from 'react-helmet'

const Home = () => {
  //Env
  const env = useSelector(state => state.env.mode || "development")
  //Firestore instance
  const firestore = useFirestore()
  //Extract data from the store
  const homeData = useSelector(state => 
    state.firestore.ordered.homeData ?
    state.firestore.ordered.homeData[0] : 
    undefined
  )

    //Extract data from firestore
  useEffect(() => {
    //Logic to detect environment
    let firebaseEnv = ""
    if(env === "development" || env === "test")
      firebaseEnv = "Development"
    else if(env === "production")
      firebaseEnv = "Production"
    
    //Query
    firestore.get({
      collection: "Environments",
      doc: firebaseEnv,
      subcollections: [{
        collection: "Pages",
        doc: "Home"
      }],
      storeAs: "homeData"
    })
    
  }, [firestore, env])

  return(
    <>
      {
        !homeData &&
        <Loading 
          page={`Home`}
        />
      }
      {
        homeData &&
        <Helmet>
          <title>{homeData.seoTitle}</title>
          <meta name="description" content={homeData.seoDescription} />
        </Helmet>
      }
      <div className="home">
        <Header 
          data={
            homeData ? {
              items: homeData.headerItems,
              gridImage: homeData.headerGridImageUrl,
              gridImageMobile: homeData.headerGridImageUrlMobile,
            } : null
          }
        />
        <Section2 
          data={
            homeData ? {
              description: homeData.section2Description,
              gridImage: homeData.section2GridImageUrl,
              gridImageMobile: homeData.section2GridImageUrlMobile,
              background: homeData.section2BackgroundImageUrl,
              centerImage: homeData.section2CenterImageUrl,
              items: homeData.section2Items
            } : null
          }
        />
        <WhyLuckyTrade 
          data={
            homeData ? {
              background: homeData.whyLuckyBackgroundImageUrl,
              gridImage: homeData.whyLuckyGridImageUrl,
              gridImageMobile: homeData.whyLuckyGridImageUrlMobile,
              items: homeData.whyLuckyList,
              title: homeData.whyLuckyTitle,
            } : null
          }
          homeData={homeData}
        />
        <Clients 
          data={
            homeData ? {
              background: homeData.clientsBackgroundImageUrl,
              gridImage: homeData.clientsGridImageUrl,
              gridImageMobile: homeData.clientsGridImageUrlMobile,
              pageSize: homeData.clientsPageSize,
              pageSizeMobile: homeData.clientsPageSizeMobile,
              title: homeData.clientsTitle
            } : null
          }
        />
        <Processes 
          data = {
            homeData ? {
              items: homeData.processes,
              background: homeData.processesBackgroundImageUrl,
              gridImage: homeData.processesGridImageUrl,
              gridImageMobile: homeData.processesGridImageUrlMobile,
              pageSize: homeData.processesPageSize,
              pageSizeMobile: homeData.processesPageSizeMobile,
              title: homeData.processesTitle,
            } : null
          }
        />
      </div>
      <Footer/>
    </>
  )

}

export default Home