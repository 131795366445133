import React, { useState, useEffect } from 'react'
//Redux
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
//React router
import { Link } from 'react-router-dom'
//Child components
import Direct from './Items/Direct'
import Dropdown from './Items/Dropdown'
import SearchBar from '../Search/SearchBar'
//Assets
import Burger from '../../assets/misc/menu_burger.svg'
import Search from '../../assets/misc/search_icon.svg'

const Navbar = () => {
  //Set show search
  const [showSearch, setShowSearch] = useState(false)
  //Show mobile dropdown
  const [showMobile, setShowMobile] = useState(false)
  //Firestore instance
  const firestore = useFirestore()
  //Env
  const env = useSelector(state => state.env.mode || "development")
  //Extract data from the store
  const navbarData = useSelector(state => state.firestore.ordered.navbarData ? state.firestore.ordered.navbarData[0] : null)
  //Current windows width
  const winWidth = useSelector(state => state.winDimensions.winWidth)

  //Extract data from firestore
  useEffect(() => {
    //Logic to detect environment
    let firebaseEnv = ""
    if(env === "development" || env === "test")
      firebaseEnv = "Development"
    else if(env === "production")
      firebaseEnv = "Production"
    
    //Query
    firestore.get({
      collection: "Environments",
      doc: firebaseEnv,
      subcollections: [{
        collection: "Pages",
        doc: "Navbar"
      }],
      storeAs: "navbarData"
    })
    
  }, [firestore, env])

  //Component return
  return (
    <div className="navbar">
      {
        navbarData &&
        <>
          <div className="navbar-container">
            <Link 
              onClick={(e) => {
                e.persist()
                setShowSearch(false)
                setShowMobile(false)
              }}
            className="navbar-container-logo" to="/">
              <div className="navbar-container-logo">
                <img 
                  alt="LuckyTrade Navigation Logo"
                  src={navbarData.imageUrl}
                />
              </div>
            </Link>
            <div className={`navbar-container-items ${showMobile && winWidth <= 768 ? "show-mobile" : ""}`}>
              {
                //Filter disabled items
                navbarData.items.filter(item => !item.disabled).map((item, idx) => 
                  item.type === "direct" ? 
                  <Direct 
                    key={`navbar-item-${idx+1}`}
                    data={{
                      ...item,
                      idx
                    }}
                    setShowMobile={setShowMobile}
                    setShowSearch={setShowSearch}
                  /> : 
                  <Dropdown 
                    key={`navbar-item-${idx+1}`}
                    data={{
                      ...item,
                      idx
                    }}
                    setShowMobile={setShowMobile}
                    setShowSearch={setShowSearch}
                  />
                )
              }
            </div>
            <div className="navbar-container-right">
              {
                navbarData.allowSearch &&
                <img 
                  onClick={(e) => {
                    e.persist()
                    setShowMobile(false)
                    return setShowSearch(!showSearch)
                  }}
                  src={Search}
                  alt="Buscar"
                />
              }
              {
                winWidth <= 768 &&
                <img 
                  onClick={(e) => {
                    e.persist()
                    setShowSearch(false)
                    return setShowMobile(!showMobile)
                  }}
                  src={Burger}
                  alt="Menu"
                />
              }
              {
                showSearch &&
                <SearchBar 
                  prefix={`navbar`}
                  items={navbarData.items}
                  setShowMobile={setShowMobile}
                  setShowSearch={setShowSearch}
                />
              }
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Navbar