import React, { useEffect } from 'react';
import './styles/main.scss';
import Main from './views/Main'

//
import { updateWinWidth } from './config/redux/actions/winDimensionsActions'
import { connect } from 'react-redux';

function App(props) {

  //Similar to componentDidMount
  useEffect(() => {
      //Update width to control navigation
    const updateWindowDimensions = () => {
      props.updateWindowWidth(window.innerWidth)
    }
    
    //Add event listener for resizings
    window.addEventListener('resize', updateWindowDimensions)
    //Remove event listener for resizings
    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [props])

  return (
    <Main />
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateWindowWidth: (winWidth) => {
      dispatch(updateWinWidth(winWidth))
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(App)