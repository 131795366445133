import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'

const Industries = ({ data }) => {

  //Selected industry
  const [selected, setSelected] = useState(0)
  //Firestore
  const firestore = useFirestore()
  //Env
  const env = useSelector(state => state.env.mode || "development")
  //Map state
  const industriesList = useSelector(state => 
    state.firestore.ordered.IndustriesList ?
    state.firestore.ordered.IndustriesList
      //Filter disabled
      .filter(industry => !industry.disabled)
      //Map for view
      .map(industry => ({
        name: industry.name,
        imageUrl: industry.homeImageUrl
      })) :
    []  
  )
  //Query industries
  useEffect(() => {
    //Logic to detect environment
    let firebaseEnv = ""
    if(env === "development" || env === "test")
      firebaseEnv = "Development"
    else if(env === "production")
      firebaseEnv = "Production"
    
    //Query
    firestore.get({
      collection: "Environments",
      doc: firebaseEnv,
      subcollections: [{
        collection: 'Industries',
        orderBy: [
          ['position', 'asc']
        ]
      }],
      storeAs: 'IndustriesList'
    })
    
  }, [firestore, env])

  return(
    <section className="home-industries"
    >
      <div 
        className="home-industries-main"
        >
        <img 
          alt={`Industria #${selected}`}
          src={
            industriesList.length > 0 ? industriesList[selected].imageUrl : ""
          }
        />
        <div className="home-industries-main-pagination">
          {
            industriesList.map((_, idx) => 
              <div 
                key={`home-industries-page-${idx+1}`}
                className={`home-industries-main-pagination-page ${idx === selected ? "selected" : ""}`}
                onClick={(e) => {
                  e.persist()
                  return setSelected(idx)
                }}
                >
                <p>
                  {
                    idx+1 < 10 ? `0${idx+1}` : idx+1
                  }
                </p>
              </div>
            )
          }
        </div>
      </div>
      <div
        className="home-industries-content"
      >
        <div
          className="home-industries-content-title"
          dangerouslySetInnerHTML={{
            __html: data ? data.title : "<h2>Cargando...</h2>"
          }}
        >
        </div>
        <div
          className="home-industries-content-menu"
          style={
            data ? {
              backgroundImage: `url(${data.menuBackground})`
            } : {}
          }
        >
          {
            industriesList.map((industry, idx) => 
              <div
                key={`home-industries-item-${idx+1}`}
                className={
                  `home-industries-content-menu-item ${selected === idx ? "selected" : ""}`
                }
                onClick={(e) => {
                  e.persist()
                  return setSelected(idx)
                }}
              >
                <p>
                  {industry.name}
                </p>
              </div>
            )
          }
        </div>
      </div>
    </section>
  )

}

export default Industries