import React from 'react'

//Components imports
const Home = React.lazy(() => import("../../views/Home/Home"))
const Services = React.lazy(() => import("../../views/Services/Services"))
const Us = React.lazy(() => import("../../views/Us/Us"))
const Industries = React.lazy(() => import("../../views/Industries/Industries"))

/**
 * Routes configuration
 */
const routerConfig = {
  routes: [
    { path: '/', name: 'Home', exact: true, component: Home},
    { path: '/Services', name: 'Servicios', exact: true, component: Services},
    { path: '/Us', name: 'Nosotros', exact: true, component: Us},
    { path: '/Industries/:id', name: 'Industrias', exact: true, component: Industries},
  ]
}

export { routerConfig }