//Initial state
const initState = {
  winWidth: window.innerWidth //Window width
}

/**
 * @param {Object} state redux store state
 * @param {Object} action has the type to select how to update the state
 */
const winDimensionReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_WIN_WIDTH': //Update the environment
       return {
          ...state,
          winWidth: action.width
       }
    default:
       return state
  }
}

export default winDimensionReducer