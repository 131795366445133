import React from 'react'
//Router dom
import { Link } from 'react-router-dom'
//Assets
import LinkArrow from '../../../assets/misc/link_arrow_dark.svg'

export const Direct = ({ data, setShowMobile, setShowSearch }) => {
  return(
    <Link 
      onClick={(e) => {
        e.persist()
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
        setShowMobile(false)
        setShowSearch(false)
      }}
      className="footer-container-items-item" to={`/${data.linksTo}`}>
      <div className="footer-container-items-item-title">
        <p>{data.title}</p>
      </div>
      <div className="footer-container-items-item-direct-link">
        <img
          src={LinkArrow}
          alt={`Flecha de link del item ${data.idx+1}`}
        />
      </div>
    </Link>
  )
}

export default Direct