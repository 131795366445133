//Initial state
const initState = {
  mode: process.env.NODE_ENV, //Environment
  fbId: 'none',
}

/**
 * @param {Object} state redux store state
 * @param {Object} action has the type to select how to update the state
 */
const envReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_ENV': //Update the environment
      return {
        ...state,
        mode: process.env.NODE_ENV
      }
    case 'UPDATE_FACEBOOK_ID': //Update the environment
      return {
        ...state,
        fbId: action.fbId
      }
    default:
       return state
  }
}

export default envReducer