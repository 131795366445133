import React, { useEffect, useState } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
//React router
import { Link } from 'react-router-dom'
//Child components
import Direct from './Items/Direct'
import Dropdown from './Items/Dropdown'
import SearchBar from '../Search/SearchBar'
//Assets
import Burger from '../../assets/misc/menu_burger_dark.svg'
import LinkArrow from '../../assets/misc/link_arrow_dark.svg'
import Search from '../../assets/misc/search_icon_dark.svg'
import Messenger from '../../assets/misc/messenger.png'

const Footer = ({ fromServices, background }) => {

  //Show search bar
  const [showSearch, setShowSearch] = useState(false)
  //Show mobile dropdown
  const [showMobile, setShowMobile] = useState(false)
  //Env
  const env = useSelector(state => state.env.mode || "development")
  const fbId = useSelector(state => state.env.fbId || "")
  //windows size
  const winWidth = useSelector(state => state.winDimensions.winWidth)
  //Firestore instance
  const firestore = useFirestore()
  //Footer data
  const footerData = useSelector(state => 
    state.firestore.ordered.footerData ?
    state.firestore.ordered.footerData[0] :
    null
  )
  //Contact data
  const contactData = useSelector(state => 
    state.firestore.ordered.contactData ?
    state.firestore.ordered.contactData[0] :
    null
  )
  //Extract data from firestore
  useEffect(() => {
    //Logic to detect environment
    let firebaseEnv = ""
    if(env === "development" || env === "test")
      firebaseEnv = "Development"
    else if(env === "production")
      firebaseEnv = "Production"
    
    //Query
    firestore.get({
      collection: "Environments",
      doc: firebaseEnv,
      subcollections: [{
        collection: "Pages",
        doc: "Footer"
      }],
      storeAs: "footerData"
    })
    //Query
    firestore.get({
      collection: "Environments",
      doc: firebaseEnv,
      subcollections: [{
        collection: "Company",
        doc: "Data"
      }],
      storeAs: "contactData"
    })
    
  }, [firestore, env])

  //Open facebook
  const openFB = (e) => {
    e.persist()
    window.open(`https://www.messenger.com/t/${fbId}`, "blank")
  }

  /**
   * Form component
   */
  const form = () => <div className={`footer-form ${fromServices ? "services-footer" : ""}`}
    style={{
      backgroundImage: `url(${( footerData && !fromServices ) ? footerData.formBackgroundImageUrl : ""})`
    }}
  >
    <div
      className={`footer-form-texts ${fromServices ? "services-footer-form-texts" : ""}`}
    >
      <p>¿Interesado en nuestros servicios?</p>
      <p>Queremos ampliar detalles contigo.</p>
      <p
        className={`footer-form-texts-last ${fromServices ? "services-footer-form-texts-last" : ""}`}
      >Contáctanos</p>
    </div>
    <hr className="footer-form-separator"/>
    <a
      href={`mailto:${contactData.email}`}
      className="footer-form-email"
    >
      <p>{contactData.email}</p>
      <img 
        alt="Link directo"
        src={LinkArrow}
      />
    </a>
    {
      !fromServices && 
      <div
        className="footer-form-phones"
      >
        {
          contactData.phoneNumbers.map((phone, idx) => 
          <p
            key={`footer-form-phone-${idx+1}`}
            className="footer-form-phones-phone"
          >{phone}</p>)
        }
      </div>
    }
    {
      contactData.allowFB
      && 
      (!fromServices || winWidth > 768)
      && 
      <img 
        className="footer-form-messenger"
        src={Messenger}
        onClick={openFB}
      />
    }
  </div>

  /**
   * Barns and offices component
   */
  const locations = () => <div className="footer-locations">
    <div
      className="footer-locations-offices"
    >
      <div
        className="footer-locations-offices-title"
      >
        <p>Oficinas</p>
      </div>
      <div
        className="footer-locations-offices-items"
      >
        {
          footerData.offices.map((office, idx) => 
          <div
            key={`footer-offices-item-${idx+1}`}
            className="footer-locations-offices-items-item"
          >
            <p>
              {office}
            </p>
          </div>)
        }
      </div>
    </div>
    <div
      className="footer-locations-barns"
    >
      <div
        className="footer-locations-barns-title"
      >
        <p>Galpón</p>
      </div>
      <div
        className="footer-locations-barns-items"
      >
        {
          footerData.barns.map((barn, idx) => 
          <div
            key={`footer-barns-item-${idx+1}`}
            className="footer-locations-barns-items-item"
          >
            <p>
              {barn}
            </p>
          </div>)
        }
      </div>
    </div>
  </div>

  return (
    <div className="footer"
      style={{
        backgroundImage: `url(${( footerData && !fromServices ) ? (winWidth <= 768 ? footerData.footerGridImageUrlMobile : footerData.footerGridImageUrl ) : ""}), url(${footerData ? ( !fromServices ? footerData.footerImageBackgroundUrl : "transparent" ) : ""})`,
      }}
    >
      {
        footerData &&
        <>
          {
            winWidth <= 768 && 
            <div 
              className={`footer-mobile ${fromServices ? "services-footer-mobile" : ""}`}
            >
              {
                !fromServices && 
                <div
                  className="footer-title"
                  dangerouslySetInnerHTML={{
                    __html: footerData.footerTitle
                  }}
                >
                </div>
              }
              {
                contactData && form()
              }
              {
                locations()
              }
            </div>
          }
          {
            winWidth > 768 && 
            <div className="footer-desktop">
              <div
                className="footer-desktop-left"
              >
                {
                  !fromServices &&
                  <div
                    className="footer-title"
                    dangerouslySetInnerHTML={{
                      __html: footerData.footerTitle
                    }}
                  >
                  </div>
                }
                {
                  locations()
                }
              </div>
              <div
                className="footer-desktop-right">
                {
                  contactData && form()
                }
              </div>
            </div>
          }
          {
            winWidth <= 768 &&
            <div className={`footer-container-items ${showMobile && winWidth <= 768 ? "show-mobile" : ""}`}>
              {
                //Filter disabled items
                footerData.items.filter(item => !item.disabled).map((item, idx) => 
                  item.type === "direct" ? 
                  <Direct 
                    key={`footer-item-${idx+1}`}
                    data={{
                      ...item,
                      idx
                    }}
                    setShowMobile={setShowMobile}
                    setShowSearch={setShowSearch}
                  /> : 
                  <Dropdown 
                    key={`footer-item-${idx+1}`}
                    data={{
                      ...item,
                      idx
                    }}
                    setShowMobile={setShowMobile}
                    setShowSearch={setShowSearch}
                  />
                )
              }
            </div>
          }
          <div className="footer-container">
            <Link 
              className="footer-container-logo"
              to="/"
              onClick={(e) => {
                e.persist()
                setShowSearch(false)
                setShowMobile(false)
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              }}
            >
              <img 
                alt="LuckyTrade Navigation Logo"
                src={footerData.imageUrl}
              />
            </Link>
            {
              winWidth > 768 &&
              <div className={`footer-container-items ${showMobile && winWidth <= 768 ? "show-mobile" : ""}`}>
                {
                  //Filter disabled items
                  footerData.items.filter(item => !item.disabled).map((item, idx) => 
                    item.type === "direct" ? 
                    <Direct 
                      key={`footer-item-${idx+1}`}
                      data={{
                        ...item,
                        idx
                      }}
                      setShowMobile={setShowMobile}
                      setShowSearch={setShowSearch}
                    /> : 
                    <Dropdown 
                      key={`footer-item-${idx+1}`}
                      data={{
                        ...item,
                        idx
                      }}
                      setShowMobile={setShowMobile}
                      setShowSearch={setShowSearch}
                    />
                  )
                }
              </div>
            }
            {
              footerData.allowSearch && 
              <div
                className="footer-container-search"
              >
                <img 
                  onClick={(e) => {
                    e.persist()
                    setShowMobile(false)
                    return setShowSearch(!showSearch)
                  }}
                  alt="Buscar página"
                  src={Search}
                />
              </div>
            }
            <div
              className="footer-container-goTop"
            >
              <img 
                onClick={(e) => {
                  e.persist()
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  })
                }}
                alt="Ir al comienzo"
                src={LinkArrow}
              />
            </div>
            {
              winWidth <= 768 &&
              <div
                className="footer-container-burger"
  
              >
                <img 
                  onClick={(e) => {
                    e.persist()
                    setShowSearch(false)
                    return setShowMobile(!showMobile)
                  }}
                  alt="Menu"
                  src={Burger}
                />
              </div>
            }
          </div>
          {
            showSearch &&
            <SearchBar 
              prefix={`footer`}
              items={footerData.items}
              setShowMobile={setShowMobile}
              setShowSearch={setShowSearch}
            />
          }
        </>
      }
      <div
        className="footer-trademark"
      >
        <p>
          ©2020 Lucky Trade. Todos los derechos reservados.
        </p>
      </div>
    </div>
  )
}

export default Footer